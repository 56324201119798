<template>
    <div>
        <div v-for="mobile in row.item.mobiles">
            <template v-if="mobile">
                <a target="_blank" :href="'tel:'+mobile">{{ mobile }}</a>
                <a target="_blank"
                   :href="'https://api.whatsapp.com/send/?phone='+cleanPhone(mobile)">
                    <font-awesome-icon :icon="['fab', 'whatsapp']" class="ml-2 text-success" size="lg"/>
                </a>
            </template>
            <br v-else>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        cleanPhone(mobile) {
            let cleaned = '';
            if (mobile) {
                cleaned = mobile.replace(/[^\d+]/g, '');
                if (cleaned.startsWith('+')) {
                    cleaned = cleaned.substring(1);
                }
            }

            return cleaned;
        }
    },
}
</script>